<template>
  <div class="order-list">
    <HeadNav/>
    <div class="list-top">
      <div
        :class="['list-item', {active: currentKey == item.key}]"
        v-for="(item, index) in stateList"
        :key="index"
        @click="chooseOrder(item.key)">
        <div v-if="item.stateName=='待付款'&&type==3">待审批</div>
        <div v-else>{{item.stateName}}</div>
      </div>
    </div>
		
    <!-- 订单列表 -->
    <yd-infinitescroll :callback="getList" ref="infinitescrollDemo">
      <div slot="list">
        <div class="content" slot="list" v-if="orderList.length > 0">
          <div
						class="order-item"
						@click.stop="orderDetail(item.id)"
						v-for="(item, index) in orderList"
						:key="index"
          >
						<div class="order-title">
							<div class="shop fs-weight">
								<span v-if="item.order_items2">
									{{ getSourceType(item.order_items2) }}
								</span>
								<span v-else-if="item.order_items">
									{{ getSourceType(item.order_items) }}
								</span>
              </div>
              <div
								v-if="getOrderState(item.order_status) === '待付款' && type == 3 && item.is_password == 0"
								class="state fs-weight"
							>待提交</div>
              <div
								v-else-if="getOrderState(item.order_status) === '待付款' && type == 3 && item.is_password == 1"
								class="state fs-weight"
							>待审批</div>
              <div v-else class="state fs-weight">
								{{getOrderState(item.order_status)}}
							</div>
            </div>
						
            <order-goods
							:goodsList="item.order_items2 || item.order_items"
						></order-goods>
						
            <div class="order-money">
              <div class="all-money">
                <span>合计：</span>
								<span v-if="type == 2">
									<span
										v-if="
											item.payway == 3 ||
											item.payway == 10 ||
											item.payway == 15 ||
											item.payway ==16
										"
										class="fs-16 fs-weight"
									>
										{{ item.integral_pay_amount != '0.00' ? `${item.integral_pay_amount}元` : `` }}
										{{ item.integral_pay_amount != '0.00' && item.integral != '0.00'? '+' : '' }}
										{{ item.integral != '0.00' ? `${item.integral}积分` : `` }}
									</span>
									<span v-else class="fs-16 fs-weight">
										{{ `${item.pay_amount}元` }}
									</span>
								</span>
                <span v-else>
                  <span
										class="fs-12 fs-weight"
										style="margin-right: 0.05rem;"
									>¥</span>
                  <span class="fs-16 fs-weight">
										{{Number(item.pay_amount).toFixed(2)}}
									</span>
                </span>
              </div>
              <div class="all-num fs-12">
								共
								{{item.order_items ? item.order_items[0].number : item.order_items2[0].number}}
								件商品
							</div>
            </div>
						
            <order-btn
							@noClick="noClick"
							@canClick="canClick"
							:orderState="item.order_status"
							:orderData="item"
							:ifMore="false"
						></order-btn>
          </div>
        </div>
				
        <!-- 空视图 -->
        <empty
					v-if="orderList.length ===0 && is_request"
					:icon="emptyIcon" 
				></empty>
				<!-- 空视图 -->
      </div>
    </yd-infinitescroll>
  </div>
</template>
<script>
import orderBtn from "@/components/order/orderBtn";
import orderGoods from "@/components/order/orderGoods";
import empty from "@/components/empty/Empty";
import HeadNav from "@/components/common/HeadNav";
import {orderMixin} from "@/mixins/orderMixin.js";
import {focusMixin} from "@/mixins/scrollerMixin";
import {getOrderList, getAfterSaleList} from "@/services/orderApi.js";
import {getStore , wxShare} from "../../utils/common.js";
import {mapState} from 'vuex';
import {baseUrl} from "../../utils/env";
import { priceShow } from '@/utils/goodsUtil';

export default {
  name: "order-list",
  components: {
    orderBtn,
    orderGoods,
    empty,
    HeadNav
  },
  computed: {
    ...mapState({
      type: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      integralRate: state => state.config.integral_rate // 积分比例
    })
  },
  mixins: [orderMixin, focusMixin],
  data() {
    return {
      emptyIcon: require("@/assets/icon_kong_dingdan@2x.png"),
      stateList: [],
      orderList: [],
      currentKey: this.$route.query.key.split('?')[0],
      page: 1,
      listRows: 10,
      no_click: false,
      is_request: 0,
			total: null	// 合计
    };
  },
  //路由改变jssdk参数重新赋值
  beforeRouteEnter(to, from, next) {
    next();
    wxShare();
  },
  methods: {
    priceShow,

    noClick() {
      this.no_click = true
    },
    canClick() {
      this.no_click = false
    },
    chooseOrder(key) {
      if (key === this.currentKey) return;
      this.$router.replace({
        path: "/order_list",
        query: {
          key: key
        }
      });
      this.currentKey = key;
      this.orderList = [];
      this.page = 0;
      this.is_request = 0;
      this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.reInit");
      this.getList();
    },

    async getList() {
      try {
        let order_status = this.getOrderId(this.currentKey);
        let ORDER_TYPE = getStore("ORDER_TYPE");
        let data, list;
        if (ORDER_TYPE == 1) {
          data = await getOrderList(this.page, this.listRows, order_status, this.type);
          list = data.data.data;
        } else {
          data = await getAfterSaleList(this.page, this.listRows, order_status);
          list = data.data.list;
        }
        this.is_request = 1;
        if (data.code === 0) {
          this.orderList = [...this.orderList, ...list];
          if (this.orderList.length === parseInt(data.data.total)) {
            // 所有数据加载完毕
            this.$refs.infinitescrollDemo.$emit(
              "ydui.infinitescroll.loadedDone"
            );
            return;
          }
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");
          this.page++;
        }
      } catch (err) {
        throw err;
      }
    },
    // 跳转订单详情
    orderDetail(order_id) {
      let order_status = this.getOrderId(this.currentKey);
      // console.log("订单详情");
      if (!this.no_click) {
        this.$router.push({
          path: "/order_detail",
          query: {
            order_id: order_id,
            order_status: order_status
          }
        });
      }
    }
  },
  created() {
    this.stateList = this.getTabList(this.currentKey);
    this.getList();
    if(this.currentKey=='SALE_DEAL'||this.currentKey=='SALE_ING'||this.currentKey=='SALE_FINISH'||this.currentKey=='SALE_REFUSE') {
		document.title = "售后订单";
	}
    else {
		document.title = "我的订单";
	}

  }
};
</script>
<style>
.order-list .yd-list-donetip {
  display: none;
}
</style>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.order-list {
  padding-top: 0.88rem;
  box-sizing: border-box;
}

.list-top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.88rem;
  background: #fff;
  position: fixed;
  top: 0.88rem;
  z-index: 999;

  .list-item {
    line-height: 0.88rem;
    .sc(0.28rem, #7d7d7d);
    text-align: center;
    flex: 1;
    position: relative;
  }

  .active {
    .sc(0.28rem, #333333);
    font-weight: bold;

    &::after {
      content: "";
      position: absolute;
      width: 0.5rem;
      height: 2px;
      background: var(--main-color);
      bottom: 0;
      left: 50%;
      margin-left: -0.25rem;
    }
  }
}

.order-item {
  padding: 0 0.3rem;
  box-sizing: border-box;
  background: #fff;
  margin-top: 0.2rem;

  .order-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @color-dark-grey;
    padding: 0.2rem 0 0.2rem 0.2rem;
    box-sizing: border-box;
    position: relative;

    .shop {
      font-size: 0.28rem;
    }

    .state {
      font-size: 0.24rem;
    }

    &::before {
      content: "";
      width: 2px;
      height: 0.2rem;
      background: var(--main-color);
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -0.1rem;
    }
  }

  .order-title-noborder {
    &::before {
      content: "";
      width: 0px;
      height: 0rem;
    }
  }

  .order-money {
    padding: 0.14rem 0 0.2rem 0;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    color: @color-dark-grey;

    .all-num {
      margin-right: 0.3rem;
      height: 0.34rem;
      line-height: 0.34rem;
    }

    .all-money {
      height: 0.34rem;
      line-height: 0.34rem;
      display: flex;
      align-items: center;
    }
  }
}

.fs-12 {
  font-size: 0.24rem;
}

.fs-16 {
  font-size: 0.32rem;
}

.fs-weight {
  font-weight: bold;
}
</style>
