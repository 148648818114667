var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-list" },
    [
      _c("HeadNav"),
      _c(
        "div",
        { staticClass: "list-top" },
        _vm._l(_vm.stateList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: ["list-item", { active: _vm.currentKey == item.key }],
              on: {
                click: function ($event) {
                  return _vm.chooseOrder(item.key)
                },
              },
            },
            [
              item.stateName == "待付款" && _vm.type == 3
                ? _c("div", [_vm._v("待审批")])
                : _c("div", [_vm._v(_vm._s(item.stateName))]),
            ]
          )
        }),
        0
      ),
      _c(
        "yd-infinitescroll",
        { ref: "infinitescrollDemo", attrs: { callback: _vm.getList } },
        [
          _c(
            "div",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _vm.orderList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "content",
                      attrs: { slot: "list" },
                      slot: "list",
                    },
                    _vm._l(_vm.orderList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "order-item",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.orderDetail(item.id)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "order-title" }, [
                            _c("div", { staticClass: "shop fs-weight" }, [
                              item.order_items2
                                ? _c("span", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.getSourceType(item.order_items2)
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ])
                                : item.order_items
                                ? _c("span", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.getSourceType(item.order_items)
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm.getOrderState(item.order_status) === "待付款" &&
                            _vm.type == 3 &&
                            item.is_password == 0
                              ? _c("div", { staticClass: "state fs-weight" }, [
                                  _vm._v("待提交"),
                                ])
                              : _vm.getOrderState(item.order_status) ===
                                  "待付款" &&
                                _vm.type == 3 &&
                                item.is_password == 1
                              ? _c("div", { staticClass: "state fs-weight" }, [
                                  _vm._v("待审批"),
                                ])
                              : _c("div", { staticClass: "state fs-weight" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.getOrderState(item.order_status)
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                          ]),
                          _c("order-goods", {
                            attrs: {
                              goodsList: item.order_items2 || item.order_items,
                            },
                          }),
                          _c("div", { staticClass: "order-money" }, [
                            _c("div", { staticClass: "all-money" }, [
                              _c("span", [_vm._v("合计：")]),
                              _vm.type == 2
                                ? _c("span", [
                                    item.payway == 3 ||
                                    item.payway == 10 ||
                                    item.payway == 15 ||
                                    item.payway == 16
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-16 fs-weight" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  item.integral_pay_amount !=
                                                    "0.00"
                                                    ? `${item.integral_pay_amount}元`
                                                    : ``
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  item.integral_pay_amount !=
                                                    "0.00" &&
                                                    item.integral != "0.00"
                                                    ? "+"
                                                    : ""
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  item.integral != "0.00"
                                                    ? `${item.integral}积分`
                                                    : ``
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "fs-16 fs-weight" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(`${item.pay_amount}元`) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                  ])
                                : _c("span", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fs-12 fs-weight",
                                        staticStyle: {
                                          "margin-right": "0.05rem",
                                        },
                                      },
                                      [_vm._v("¥")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "fs-16 fs-weight" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              Number(item.pay_amount).toFixed(2)
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]),
                            _c("div", { staticClass: "all-num fs-12" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t共\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    item.order_items
                                      ? item.order_items[0].number
                                      : item.order_items2[0].number
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t件商品\n\t\t\t\t\t\t\t"
                              ),
                            ]),
                          ]),
                          _c("order-btn", {
                            attrs: {
                              orderState: item.order_status,
                              orderData: item,
                              ifMore: false,
                            },
                            on: {
                              noClick: _vm.noClick,
                              canClick: _vm.canClick,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.orderList.length === 0 && _vm.is_request
                ? _c("empty", { attrs: { icon: _vm.emptyIcon } })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }